<template>
  <div class="text-no-warp">
    <v-toolbar color="primary" dark>
      <v-app-bar-nav-icon>
        <v-menu
          v-model="user_menu"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="white"
              class="indigo--text font-weight-bold"
              fab
              v-bind="attrs"
              v-on="on"
            >
              {{ getNickname() }}
            </v-btn>
          </template>

          <v-card>
            <v-list>
              <v-list-item>
                <v-list-item-avatar>
                  <!-- <img
                    src="http://127.0.0.1/FN2021/host/avatar.png"
                    :alt="getNickname()"
                  /> -->
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>{{ getNickname() }}</v-list-item-title>
                  <v-list-item-subtitle
                    >งวด
                    <strong class="green--text">{{ period_th }}</strong> สถานะ
                    <strong class="green--text"
                      >เปิด</strong
                    ></v-list-item-subtitle
                  >
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn
                    :class="fav ? 'red--text' : ''"
                    icon
                    @click="fav = !fav"
                  >
                    <v-icon>mdi-heart</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>

            <v-divider></v-divider>

            <v-list>
              <v-list-item>
                <v-list-item-action>
                  <!-- <v-switch
                v-model="message"
                color="purple"
              ></v-switch> -->
                </v-list-item-action>
                <v-list-item-title></v-list-item-title>
              </v-list-item>

              <v-list-item>
                <v-list-item-action>
                  <!-- <v-switch
                v-model="hints"
                color="purple"
              ></v-switch> -->
                </v-list-item-action>
                <v-list-item-title class="text-left">
                  <v-btn @click="SignOut()" outlined color="red">
                    <span>ออกจากระบบ</span>
                    <v-icon right>mdi-exit-to-app</v-icon>
                  </v-btn>
                </v-list-item-title>
              </v-list-item>
            </v-list>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="user_menu = false">
                ปิด
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-app-bar-nav-icon>

      <!-- <v-layout justify-end row wrap></v-layout> -->
      <v-toolbar-title>
        <div>
          <div class="text-body-2 grey--text text--lighten-2">
            งวด
            <span class="white--text">{{ period_th }}</span>
            สถานะ
            <span class="white--text" v-if="period.status === '1'">เปิด</span>
            <span class="red white--text" v-if="period.status === '0'"
              >ปิดรับ</span
            >
          </div>
          <div class="mt-1 text-body-2 grey--text text--lighten-2">
            ปิด
            <span class="white--text" v-if="period.isautoclose === '0'"
              >(MT)</span
            >
            <span class="white--text" v-if="period.isautoclose === '1'"
              >(AT)</span
            >

            <span class="white--text"> {{ getCloseDate() }}</span> เวลา
            <span class="white--text"> {{ getCloseTime() }}</span>
          </div>
        </div>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn
          v-for="item in menu"
          :key="item.icon"
          :to="item.route"
          text
          color="white"
        >
          <v-icon left>
            {{ item.icon }}
          </v-icon>

          {{ item.title }}</v-btn
        >
      </v-toolbar-items>

      <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </v-toolbar>
  </div>
</template>

<script>
import globalFunctionMixin from "@/mixins/globalFunctionMixin";

export default {
  name: "ToolBar",
  mixins: [globalFunctionMixin],

  computed: {
    period_th() {
      return this.getAbbrPeriodThai(sessionStorage.getItem("period"));
    },
  },
  created() {
    this.fetch_data();
  },
  methods: {
    async fetch_data() {
      let credentials = {
        PeriodDT: sessionStorage.getItem("period"),
        Method: "get",
      };
      //   console.log(credentials);
      await this.$store.dispatch("period/get_data", credentials).then(
        (response) => {
          console.log(response);
          this.period.status = response.PERIOD_DETAIL.IsOpen;
          this.period.isautoclose = response.PERIOD_DETAIL.IsAutoClose;

          //   2023-06-01 14:00:00
          this.period.closedate = response.PERIOD_DETAIL.CloseTime.split(
            " "
          )[0];
          this.period.closetime =
            response.PERIOD_DETAIL.CloseTime.split(" ")[1].split(":")[0] +
            "." +
            response.PERIOD_DETAIL.CloseTime.split(" ")[1].split(":")[1];
        },
        (error) => {
          console.log(error);
        }
      );
    },
    getNickname() {
      return sessionStorage.getItem("nickname");
    },
    getCloseDate() {
      return this.getAbbrPeriodThai(this.period.closedate);
    },
    getCloseTime() {
      return this.period.closetime + " น.";
    },
    SignOut() {
      //   this.$fire({
      //     type: "error",
      //     title: '<strong>HTML <u>example</u></strong>',
      //     text: "Something went wrong!",
      //     footer: '<a href="">Why do I have this issue?</a>',
      //   });
      this.$confirm("", "กดยืนยันเพื่อออกจากระบบ", "question")
        .then(() => {
          localStorage.removeItem("blog_token");
          sessionStorage.removeItem("host_id");
          sessionStorage.removeItem("period");
          sessionStorage.removeItem("nickname");
          this.$router.push("/Login");
        })
        .catch(() => {});
    },
  },
  data: () => ({
    menu: [
      { icon: "mdi-file-document", title: "ข้อมูล", route: "/menu_data" },
      { icon: "mdi-account", title: "ลูกค้า", route: "/menu_cus" },
      { icon: "mdi-account-group", title: "เจ้ามือ", route: "/menu_export" },
      { icon: "mdi-face-agent", title: "พนักงาน", route: "/menu_staff" },
      { icon: "mdi-history", title: "จัดการ", route: "/menu_manage" },
    ],
    period: { status: "0", isautoclose: "0", closedate: "", closetime: "" },
    user_menu: false,
    fav: true,
  }),
};
</script>

<style></style>
