<template>
  <v-bottom-navigation
    fixed
    background-color="grey lighten-4"
    :value="value"
    hidden="false"
    color="light-blue accent-5"
    height="45"
    grow
    class="hidden-md-and-up"
  >
    <v-btn to="/menu_data">
      <span>ข้อมูล</span>

      <v-icon>mdi-file-document</v-icon>
    </v-btn>

    <v-btn to="/menu_cus">
      <span>ลูกค้า</span>

      <v-icon>mdi-account</v-icon>
    </v-btn>

    <v-btn to="/menu_export">
      <span>เจ้ามือ</span>

      <v-icon>mdi-account-group</v-icon>
    </v-btn>
    <v-btn to="/menu_staff">
      <span>พนักงาน</span>

      <v-icon>mdi-face-agent</v-icon>
    </v-btn>
    <v-btn to="/menu_manage">
      <span>จัดการ</span>

      <v-icon>mdi-history</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
export default {
  name: "BottomNavigation",

  data: () => ({
    value: 0,
  }),
  methods: {
    SetID(ID) {
      this.value = ID;
    },
  },
};
</script>

<style scoped>
.v-item-group.v-bottom-navigation .v-btn.v-size--default {
  height: inherit !important;
}

::v-deep span {
  font-size: 0.85em !important;
}

/* .v-bottom-navigation {
  justify-content: start;
  overflow-x: scroll;
} */
</style>
