<template>
  <div class="menu_data">

<ToolBar/>
    <div>
      <v-container>
        <v-row dense>
          <v-col cols="12" class="pa-0">
            <v-sheet color="">
              <v-chip
                :x-small="test"
                class=""
                color="red darken-1"
                label
                text-color="white"
              >
                <v-icon left>
                  mdi-label
                </v-icon>
                เลขอั้น3ตัว
              </v-chip>
              <v-chip
                x-small
                class="font-weight-black mr-1"
                text-color="red darken-1"
                v-for="(item, i) in hp_three_item"
                :key="i"
                >{{ item.text
                }}<v-icon right color="red darken-1" v-if="item.convert">
                  mdi-repeat</v-icon
                >
              </v-chip>
            </v-sheet>
          </v-col>

          <v-col cols="12" class="pa-0">
            <v-sheet class="mt-1" color="">
              <v-chip x-small class="mr-1" color="red" label text-color="white">
                <v-icon left>
                  mdi-label
                </v-icon>
                เลขอั้น2ตัว
              </v-chip>
              <v-chip
                x-small
                class="font-weight-black mr-1"
                text-color="red darken-1"
                v-for="(item, i) in hp_two_item"
                :key="i"
                >{{ item.text
                }}<v-icon right color="red darken-1" v-if="item.convert">
                  mdi-repeat</v-icon
                >
              </v-chip>
            </v-sheet>
          </v-col>

          <v-col cols="12" class="pa-0">
            <v-sheet class="mt-1" color="">
              <v-chip
                x-small
                class="mr-1"
                color="pink"
                label
                text-color="white"
              >
                <v-icon left>
                  mdi-label
                </v-icon>
                เลขเต็ม3ตัว
              </v-chip>
              <v-chip
                x-small
                class="font-weight-black mr-1"
                text-color="pink"
                v-for="(item, i) in limit_three_item"
                :key="i"
                >{{ item.text
                }}<v-icon right color="red darken-1" v-if="item.convert">
                  mdi-repeat</v-icon
                >
              </v-chip>
            </v-sheet>
          </v-col>

          <v-col cols="12" class="pa-0">
            <v-sheet class="mt-1" color="">
              <v-chip
                x-small
                class="mr-1"
                color="pink"
                label
                text-color="white"
              >
                <v-icon left>
                  mdi-label
                </v-icon>
                เลขเต็ม2ตัว
              </v-chip>
              <v-chip
                x-small
                class="font-weight-black mr-1"
                text-color="pink"
                v-for="(item, i) in limit_two_item"
                :key="i"
                >{{ item.text
                }}<v-icon right color="red darken-1" v-if="item.convert">
                  mdi-repeat</v-icon
                >
              </v-chip>
            </v-sheet>
          </v-col>
        </v-row>

        <v-divider></v-divider>
        <!-- ข้อมูล -->
        <v-row dense>
          <v-col cols="12"
            ><v-subheader
              class="subtitle-1 text-decoration-underline d-flex justify-center"
              >ข้อมูลงวด</v-subheader
            ></v-col
          >
          <v-col cols="3" md="3" v-for="(item, i) in menu_data_item" :key="i">
            <v-layout
              column
              align-center
              link
              @click="$router.push({ path: item.route })"
            >
              <v-flex>
                <v-avatar size="60" color="light-blue lighten-5">
                  <v-icon
                    x-large
                    color="light-blue accent-3"
                  >{{ item.icon }}</v-icon>
                </v-avatar>
                <p
                  class="caption font-weight-bold mt-1 text-center"
                  v-text="item.text"
                ></p>
              </v-flex>
            </v-layout>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <!-- ตีออกเ -->
        <v-row dense>
          <v-col cols="12"
            ><v-subheader
              class="subtitle-1 text-decoration-underline d-flex justify-center"
              >ตีออก</v-subheader
            ></v-col
          >
          <v-col cols="3" md="3" v-for="(item, i) in menu_export_item" :key="i">
            <v-layout
              column
              align-center
              link
              @click="$router.push({ path: item.route })"
            >
              <v-flex>
                <v-avatar size="60" color="light-blue lighten-5">
                  <v-icon
                    x-large
                    color="light-blue accent-3"
                  >{{item.icon}}</v-icon>
                </v-avatar>
                <p
                  class="caption font-weight-bold mt-1 text-center"
                  v-text="item.text"
                ></p>
              </v-flex>
            </v-layout>
          </v-col>
        </v-row>
        <!-- ตั้งค่า -->
        <v-row dense>
          <v-col cols="12"
            ><v-subheader
              class="subtitle-1 text-decoration-underline d-flex justify-center"
              >ตั้งค่างวด</v-subheader
            ></v-col
          >
          <v-col
            cols="3"
            md="3"
            v-for="(item, i) in menu_setting_item"
            :key="i"
          >
            <v-layout
              column
              align-center
              link
              @click="$router.push({ path: item.route })"
            >
              <v-flex>
                <v-avatar size="60" color="light-blue lighten-5">
                  <v-icon
                    x-large
                    color="light-blue accent-3"
                  >{{ item.icon }}</v-icon>
                </v-avatar>
                <p
                  class="caption font-weight-bold mt-1 text-center"
                  v-text="item.text"
                ></p>
              </v-flex>
            </v-layout>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <!-- รายงาน -->
        <v-row dense>
          <v-col cols="12"
            ><v-subheader
              class="subtitle-1 text-decoration-underline d-flex justify-center"
              >รายงาน</v-subheader
            ></v-col
          >
          <v-col cols="3" md="3" v-for="(item, i) in menu_report_item" :key="i">
            <v-layout
              column
              align-center
              link
              @click="$router.push({ path: item.route })"
            >
              <v-flex>
                <v-avatar size="60" color="light-blue lighten-5">
                  <v-icon
                    x-large
                    color="light-blue accent-3"
                  >{{ item.icon }}</v-icon>
                </v-avatar>
                <p
                  class="caption font-weight-bold mt-1 text-center"
                  v-text="item.text"
                ></p>
              </v-flex>
            </v-layout>
          </v-col>
        </v-row>
        <!-- <v-divider></v-divider> -->
        <v-card height="50" flat> </v-card>
      </v-container>
    </div>

    <BottomNavigation />
  </div>
</template>

<script>

import ToolBar from "@/components/Layouts/ToolBar";
import BottomNavigation from "@/components/Layouts/BottomNavigation";

export default {
  name: "Menu_Data",
  components: {
    BottomNavigation,
    ToolBar,
    // SideBar,
  },

  data: () => ({
    hp_three_item: [
      { text: "123", convert: false },
      { text: "456", convert: true },
      { text: "789", convert: true },
    ],
    hp_two_item: [
      { text: "12", convert: false },
      { text: "34", convert: true },
      { text: "56", convert: true },
    ],
    limit_three_item: [
      { text: "000", convert: false },
      { text: "012", convert: true },
      { text: "035", convert: true },
    ],
    limit_two_item: [
      { text: "33", convert: false },
      { text: "45", convert: true },
      { text: "56", convert: true },
    ],
    menu_data_item: [
      { text: "ภาพรวม", icon: "mdi-database", route: "/limit_number" },
      { text: "ยอดรวม", icon: "mdi-sigma", route: "/limit_number" },
      { text: "กำไรขาดทุน", icon: "mdi-currency-usd", route: "/limit_number" },
      { text: "ค้นหาตัวซื้อ", icon: "mdi-file-find", route: "/limit_number" },
      { text: "เลขอั้น", icon: "mdi-percent", route: "/hp_number" },
      {
        text: "เลขเต็ม",
        icon: "mdi-alpha-x-circle-outline",
        route: "/limit_number",
      },
      {
        text: "ตั้งขาดทุน",
        icon: "mdi-wallet-outline",
        route: "/limit_number",
      },
    ],
    menu_export_item: [
      { text: "ตั้งเจ้ามือ", icon: "mdi-account-tie", route: "/limit_number" },
      {
        text: "การตีออก",
        icon: "mdi-chart-areaspline",
        route: "/limit_number",
      },
      { text: "โพยตีออก", icon: "mdi-file", route: "/export_list" },
      { text: "ยอดตีออก", icon: "mdi-cash-100", route: "/limit_number" },
    ],
    menu_setting_item: [
      { text: "เปิด/ปิดงวด", icon: "mdi-tune", route: "/limit_number" },
      {
        text: "ประมวลผล",
        icon: "mdi-license",
        route: "/limit_number",
      },
    ],
    menu_report_item: [
      {
        text: "ใบปะหน้า",
        icon: "mdi-microsoft-onenote",
        route: "/limit_number",
      },
      {
        text: "บัญชีรับ-จ่าย",
        icon: "mdi-text-account",
        route: "/limit_number",
      },
    ],
    test: true,
  }),
  methods: {},
};
</script>

<style></style>
